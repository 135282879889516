/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            image
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  const pixelScript = `
    (function () {
      var params = {
        hpr: "Viento Beach Lodge",
        hpid: "206284",
        sha256_eml: "",
        sha1_eml: "",
        md5_eml: "",
        ccid: "",
        pt: "HOME_PAGE"
      };
      params.et = {"HOME_PAGE":null,"SEARCH":"hs","PRODUCT":"hpr","SHOPPING_CART":"hcart","CONVERSION":"hc","TRACKING":null}[params.pt] || '';
      try{params = Object.assign({}, sjrn_params, params);}catch(e){}
      var paramsArr = [];
      for(key in params) { paramsArr.push(key + '=' + encodeURIComponent(params[key])) };
      var pl = document.createElement('iframe');
      pl.type = 'text/html';
      pl.setAttribute('style','height:0; width: 0; display:none;');
      pl.async = true;
      pl.src = "https://static.sojern.com/cip/c/45.html?f_v=cp_v3_js&p_v=1&" + paramsArr.join('&');
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(pl);
    })();
  `

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      link={[
        {
          rel: "canonical",
          href: site.siteMetadata?.siteUrl ?? null,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: "og:image",
          content: site.siteMetadata?.image ?? null,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: "twitter:image",
          content: site.siteMetadata?.image ?? null,
        },
      ].concat(meta)}
      script={[
        {
          src: "https://static.sojern.com/utils/sjrn_autocx.js",
          type: "text/javascript",
        },
        {
          type: "text/javascript",
          innerHTML: pixelScript,
        },
      ]}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
